import React from 'react';
import { Helmet } from 'react-helmet';
import StandardFrame from '../components/StandardFrame';
import { StaticImage } from 'gatsby-plugin-image';
import PageSection from '../components/Sections/PageSection';
import PageSectionWithImage from '../components/Sections/PageSectionWithImage';
import PageSectionTitle from '../components/Sections/PageSectionTitle';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  List,
  ListItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { ContactForm } from '../components/Sections/ContactForm';

export default () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Helmet title='Szkolenia użytkowe' />
      <StandardFrame>
        <PageSectionWithImage
          maxWidth='lg'
          color='white'
          bgimage={
            <StaticImage
              style={{ height: '100%' }}
              layout='fullWidth'
              quality={95}
              src='../images/szkolenia_uzytkowe_hero.jpg'
              alt=''
            />
          }
        >
          <PageSectionTitle>Szkolenia użytkowe</PageSectionTitle>
          <Box>
            <Typography color='white' gutterBottom>
              Szkolenia użytkowe z programu Mediporta skoncentrowane są na praktycznych aspektach korzystania z systemu
              Mediporta.
            </Typography>
            <Typography color='white' gutterBottom>
              Bez względu na to, czy jesteś nowym użytkownikiem, który dopiero zaczyna swoją pracę z systemem, czy
              doświadczonym użytkownikiem, który chce poszerzyć swoje umiejętności, nasze szkolenie spełnią Twoje
              oczekiwania i nauczą Cię obsługiwać system.
            </Typography>
          </Box>
        </PageSectionWithImage>
        <PageSection bgcolor='background.defauly' maxWidth='lg'>
          <Typography>
            Szkolenia użytkowe z programu Mediporta skoncentrowane są na praktycznych aspektach korzystania z systemu
            Mediporta. Bez względu na to, czy jesteś nowym użytkownikiem, który dopiero zaczyna swoją pracę z systemem,
            czy doświadczonym użytkownikiem, który chce poszerzyć swoje umiejętności, nasze szkolenie spełnią Twoje
            oczekiwania i nauczą Cię obsługiwać system.
          </Typography>

          <Stack direction={isMobile ? 'column' : 'row'} marginTop={4} gap={4} alignItems={'flex-start'}>
            <Stack>
              <Typography variant='h6' component={'span'}>
                Dlaczego warto skorzystać z usługi?
              </Typography>
              <List
                dense
                sx={{
                  listStyle: 'disc',
                  listStylePosition: 'inside'
                }}
              >
                <ListItem
                  sx={{
                    display: 'list-item'
                  }}
                >
                  omówienie wszystkich funkcji programu, które zwiększą efektywność codziennej pracy w placówce
                  medycznej
                </ListItem>
                <ListItem
                  sx={{
                    display: 'list-item'
                  }}
                >
                  zakres przekazywanej wiedzy dostosowany tak, aby sprostać oczekiwaniom zarówno osób początkujących,
                  jak i zaawansowanych użytkowników
                </ListItem>
                <ListItem
                  sx={{
                    display: 'list-item'
                  }}
                >
                  szkolenie uwzględnia różnorodne poziomy umiejętności i potrzeby uczestników
                </ListItem>
                <ListItem
                  sx={{
                    display: 'list-item'
                  }}
                >
                  praktyczne przykłady i wskazówki, które ułatwią efektywne korzystanie z programu w codziennej pracy
                </ListItem>
                <ListItem
                  sx={{
                    display: 'list-item'
                  }}
                >
                  szkolenie zdalne, prowadzone przez aplikację Teams
                </ListItem>
                <ListItem
                  sx={{
                    display: 'list-item'
                  }}
                >
                  usługa realizowana przez osobę posiadającą wieloletnie doświadczenie w obsłudze programu Mediporta
                </ListItem>
                <ListItem
                  sx={{
                    display: 'list-item'
                  }}
                >
                  szkolenie prowadzone w blokach tematycznych w oparciu o poziomy uprawnień i ról w systemie
                </ListItem>
                <ListItem
                  sx={{
                    display: 'list-item'
                  }}
                >
                  możliwość zadawania pytań
                </ListItem>
                <ListItem
                  sx={{
                    display: 'list-item'
                  }}
                >
                  podniesienie satysfakcji i efektywności w codziennej pracy z systemem
                </ListItem>
              </List>
            </Stack>
            <Stack>
              <Card
                variant='outlined'
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  width: isMobile ? '100%' : 325,
                  backgroundColor: 'tertiary.main'
                }}
              >
                <CardContent sx={{ padding: 0 }}>
                  <Box sx={{ backgroundColor: 'primary.main' }} paddingX={2} paddingY={1}>
                    <Typography
                      textTransform={'uppercase'}
                      color={'background.default'}
                      textAlign={'center'}
                      variant='h4'
                      component={'div'}
                    >
                      Cena szkolenia
                    </Typography>
                  </Box>
                  <Stack alignItems={'center'} marginY={2}>
                    <Box>
                      <Typography component={'span'} fontSize={'1.15rem'} fontWeight={700}>
                        od
                      </Typography>
                      <Typography component={'span'} fontWeight={700} fontSize={'2rem'}>
                        {' '}
                        249 zł
                      </Typography>
                      <Typography component={'span'} fontSize={'1.15rem'} fontWeight={700}>
                        / godzinę
                      </Typography>
                    </Box>
                    <Divider
                      flexItem
                      variant='middle'
                      sx={{
                        borderColor: 'secondary.divider',
                        borderWidth: 1,
                        borderBottom: 'none',
                        marginBottom: 0.5
                      }}
                    />
                    <Typography>cena netto</Typography>
                  </Stack>
                  <Box sx={{ backgroundColor: 'secondary.main' }} paddingX={1} marginBottom={2}>
                    <Typography variant='body1' textAlign={'center'}>
                      Średni czas szkolenia w jednym bloku tematycznym to <b>1h</b>, w zależności od ustalonego zakresu.
                    </Typography>
                  </Box>
                </CardContent>
                <CardActions
                  sx={{
                    flexDirection: 'column',
                    gap: 1.5,
                    paddingBottom: 3,
                    paddingX: 4
                  }}
                >
                  <Typography variant='body2' textAlign={'center'}>
                    Aby zamówić szkolenie, wypełnij formularz kontaktowy
                  </Typography>
                </CardActions>
              </Card>
            </Stack>
          </Stack>
        </PageSection>
        <ContactForm bgcolor={'tertiary.main'} />
      </StandardFrame>
    </>
  );
};
